import { Image, VStack, Box, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ProjectContainer from "../../components/Project/ProjectContainer";
import { Helmet } from "react-helmet";

const Project = () => {
  const [isAnimationActive, setIsAnimationActive] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnimationActive(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const styles = {
    text: {
      paddingRight: "68px",
      fontFamily: "poppins",
      fontWeight: "bold",
      textColor: "white",
      fontSize: "120px",
      letterSpacing: "5px",
      animation: isAnimationActive ? "shrinkAnimation 5s linear" : "none",
    },
  };
  const textStyle = {
    color: "white",
    WebkitTextFillColor: "transparent",
    WebkitTextStrokeWidth: "1px",
    WebkitTextStrokeColor: "white",
    letterSpacing: "5px",
    animation: isAnimationActive ? "shrinkAnimation 5s linear" : "none",
  };
  return (
    <VStack width={"full"} margin={"0 !important"}>
      <Helmet>
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
        </style>
      </Helmet>
      <VStack
        className="ProjectCoverContainer"
        width={"full"}
        height="100vh"
        display={"flex"}
        justifyContent={"center"}
        alignItems={"flex-end"}
        position={"relative"}
        backgroundImage={"url(/image/projectCover2.jpg)"}
        backgroundRepeat={"no-repeat"}
        backgroundSize={"cover"}
        padding={"32px"}
      >
        <style>
          {`
          @keyframes shrinkAnimation {
            from {
              letter-spacing: 10px;
            }
            to {
              letter-spacing: 5px;
            }
          }
        `}
        </style>
        <Text textColor={"white"} style={styles.text}>
          PROJECT
        </Text>
        <Text
          bottom={"25%"}
          right={"15%"}
          position={"absolute"}
          fontFamily={"poppins"}
          style={textStyle}
          fontSize={"120px"}
        >
          PROJECT
        </Text>
      </VStack>
      <ProjectContainer />
    </VStack>
  );
};

export default Project;
