import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={42}
    height={46}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={21} cy={23} r={2} fill="#09c" />
    <path
      d="M29 23c0 6.259-.994 11.88-2.567 15.9-.788 2.012-1.701 3.573-2.664 4.613C22.807 44.553 21.871 45 21 45c-.87 0-1.807-.447-2.77-1.487-.962-1.04-1.875-2.601-2.663-4.614C13.995 34.88 13 29.26 13 23c0-6.259.995-11.88 2.567-15.9.788-2.012 1.701-3.573 2.664-4.613C19.193 1.447 20.129 1 21 1c.87 0 1.807.447 2.77 1.487.962 1.04 1.875 2.601 2.663 4.614C28.006 11.12 29 16.74 29 23Z"
      stroke="#0099cc"
      strokeWidth={2}
    />
    <path
      d="M24.958 30.102c-5.418 3.128-10.792 5.057-15.074 5.674-2.144.309-3.962.281-5.357-.051-1.391-.332-2.268-.94-2.721-1.725-.453-.785-.542-1.848-.133-3.22.41-1.373 1.294-2.961 2.634-4.664 2.676-3.4 7.033-7.089 12.451-10.217 5.419-3.129 10.793-5.058 15.075-5.675 2.144-.309 3.962-.28 5.356.051 1.392.332 2.269.94 2.722 1.725.453.785.541 1.849.133 3.22-.41 1.373-1.295 2.961-2.634 4.664-2.676 3.4-7.033 7.09-12.452 10.218Z"
      stroke="#0099cc"
      strokeWidth={2}
    />
    <path
      d="M24.958 15.898c-5.418-3.128-10.792-5.057-15.074-5.674-2.144-.309-3.962-.281-5.357.05-1.391.333-2.268.94-2.721 1.726-.453.785-.542 1.848-.133 3.22.41 1.373 1.294 2.961 2.634 4.663 2.676 3.4 7.033 7.09 12.451 10.218 5.419 3.128 10.793 5.057 15.075 5.675 2.144.308 3.962.28 5.356-.052 1.392-.331 2.269-.94 2.722-1.724.453-.785.541-1.849.133-3.22-.41-1.373-1.295-2.962-2.634-4.664-2.676-3.4-7.033-7.09-12.452-10.218Z"
      stroke="#0099cc"
      strokeWidth={2}
    />
  </svg>
);

export default SvgComponent;
