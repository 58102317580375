import { HStack, Text, Image, Box, VStack } from "@chakra-ui/react";
import React from "react";
import ProductContainer from "../../components/Product.js/ProductContainer";

const Product = () => {
  return (
    <VStack
      width="full"
      paddingTop={"120px"}
      display={"flex"}
      justifyContent="center"
      paddingBottom={"64px"}
      spacing={"64px"}
      margin={"0 !important"}
    >
      <Box width="1280px" display={"flex"} justifyContent="center">
        <HStack spacing={"32px"}>
          <VStack
            width={"50%"}
            display="flex"
            alignItems="flex-start"
            textAlign={"left"}
            padding="0px 32px"
          >
            <Text
              fontWeight={"700"}
              textAlign={"start"}
              textColor={"#0099cc"}
              fontSize={"24px"}
              fontFamily={"'Dancing Script', cursive"}
            >
              Tại sao chọn chúng tôi?
            </Text>
            <Text textAlign={"justify"}>
              Với đội ngũ kỹ sư chất lượng và nhiệt huyết kết hợp với nền tảng
              <span style={{ fontWeight: 600 }}> Mitsubishi Electric</span>, đội
              ngũ <span style={{ fontWeight: 600 }}>AUEVN</span> cung cấp cho
              khách hàng dịch vụ và tư vấn tốt nhất trong từng sản phẩm của{" "}
              <span style={{ fontWeight: 600 }}>Mitsubishi Electric</span>.
              Ngoài ra, <span style={{ fontWeight: 600 }}>AUEVN</span> còn tạo
              ra các giải pháp dựa trên nền tảng{" "}
              <a
                href="http://www.iconics.com"
                rel="noreferrer"
                target="_blank"
                style={{
                  color: "#0099cc",
                }}
              >
                Iconics
              </a>{" "}
              để mang lại cho khách hàng sự tiện lợi trong việc quản lý, giám
              sát thiết bị và hệ thống trong nhà máy một cách tối ưu và hiệu quả
              nhất. Chúng tôi cam kết đáp ứng nhu cầu của khách hàng bằng cách
              cung cấp các sản phẩm và dịch vụ chất lượng cao cùng với các giải
              pháp đổi mới và hiệu quả.
            </Text>
          </VStack>
          <Image width={"800px"} src="/image/backgroundProduct.png" />
        </HStack>
      </Box>
      <ProductContainer />
    </VStack>
  );
};

export default Product;
