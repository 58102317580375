import { HStack, Image, VStack, Text } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Solution.css";

const SolutionContainer = () => {
  useEffect(() => {
    AOS.init({
      duration: 500,
      easing: "ease-in-out",
      once: false,
      mirror: false,
    });
  }, []);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const Card = ({ Title, image, SubContent }) => {
    return (
      <VStack
        width={isMobile ? "80%" : "45%"}
        position="relative"
        background="#ffffff"
        height="300px"
        borderWidth={"1px"}
        borderColor="#ccc"
        borderRadius={"8px"}
        transition="0.5s"
        className="CardSolutionContainer"
      >
        <Image
          className="Image-CardSolutionContainer"
          width={"full"}
          height={"full"}
          objectFit={"cover"}
          borderRadius={"8px"}
          transition={"0.5s"}
          src={image}
        />
        <Text
          className="CardText"
          borderBottomRadius={"8px"}
          width={"full"}
          lineHeight={"50px"}
          backgroundColor={"#fff"}
          position={"absolute"}
          bottom="0"
        >
          {Title}
        </Text>
        <VStack
          className="SubContent"
          backgroundColor={"transparent"}
          width={"full"}
          height={"full"}
          position={"absolute"}
          top={0}
          opacity={0}
          textColor={"white"}
          alignItems={"flex-start"}
          alignContent={"space-between"}
          justifyContent={"flex-end"}
          textAlign={"left"}
          padding={"32px 32px 64px 32px"}
          transition={"0.7s"}
        >
          {SubContent?.map((item, index) => (
            <Text key={`subContent ${index}`}>
              {item === "" ? "" : "-"} {item}
            </Text>
          ))}
        </VStack>
      </VStack>
    );
  };
  return (
    <VStack
      width={"full"}
      backgroundSize="cover"
      display={"flex"}
      justifyContent="center"
      position={"relative"}
      paddingBottom={0}
      paddingTop={"16px"}
    >
      {/* <Image
        _hover={{
          cursor: "pointer",
        }}
        onClick={() => {
          window.open(
            "https://matterport.com/discover/space/D96mwmf11FV?fbclid=IwAR1Qp-Fr1fNoCXgF7mstHSh4-LdzeFzk1kH_Hg_qU1KY21bkyk02a5G_9FY",
            "_blank"
          );
        }}
        width={"full"}
        filter="brightness(30%)"
        objectFit="cover"
        height="500px"
        src="/image/solutionHomepage.jpg"
        alt="Solution Homepage AUE VietNam"
      /> */}
      <VStack
        backgroundImage={"url(/image/solutionHomepage.jpg)"}
        backgroundRepeat={"no-repeat"}
        backgroundSize={"100% 100%"}
        display={"flex"}
        alignItems="center"
        justifyContent={"flex-end"}
        justifyItems={"flex-start"}
        textAlign={"left"}
        // right={isMobile ? null : 0}
        textColor={"white"}
        width={"100%"}
        minHeight={"400px"}
        // position={"absolute"}
      >
        <VStack
          justifyContent={"flex-end"}
          justifyItems={"flex-start"}
          alignItems={"flex-start"}
          paddingBottom={"32px"}
          width={"1280px"}
        >
          <Text fontWeight={600} textColor={"#fff"} fontSize={"16px"}>
            TRUNG TÂM ĐÀO TẠO TỰ ĐỘNG HÓA FASC
          </Text>
          <Text fontSize="14px">
            Với mong muốn góp phần xây dựng nên những nhà máy sản xuất tự động
            hóa chuyên nghiệp, có khả năng vận hành với hiệu quả vượt trội cũng
            như chất lượng cao, đặc biệt là những nhà máy công nghiệp, cụm công
            nghiệp tập trung tại khu vực miền Trung, Việt Nam; Mitsubishi
            Electric đã kết hợp cùng AUE Việt Nam triển khai thực hiện dự án xây
            dựng Trung tâm Giải pháp Tự động hóa (Factory Automation Solutions
            Center) để mở rộng quy mô hoạt động và đáp ứng nhu cầu tư vấn về các
            sản phẩm tự động hóa cho mọi khách hàng tại khu vực miền Trung.
          </Text>
          <button
            onClick={() => {
              window.open(
                "https://matterport.com/discover/space/D96mwmf11FV?fbclid=IwAR1Qp-Fr1fNoCXgF7mstHSh4-LdzeFzk1kH_Hg_qU1KY21bkyk02a5G_9FY",
                "_blank"
              );
            }}
            class="Button3dView"
          >
            3D View
          </button>
        </VStack>

        {/* <Text width={isMobile ? "100%" : "60%"}>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </Text> */}
      </VStack>

      {isMobile ? (
        <VStack width={"100%"} spacing={"16px"} padding="16px">
          <Card
            Title={"Thiết bị đóng cắt LVS"}
            image={"/image/Trainning/acb.jpg"}
            SubContent={[
              "Tổng quan về thiết bị đóng cắt",
              "",
              "",
              "Cách tính toán, lựa chọn thiết bị",
              "",
              "",
              "Kiểm tra & lắp đặt máy cắt ACB",
              "",
              "",
            ]}
          />
          <Card
            Title={"Phần mềm ICONICS"}
            image={"/image/Trainning/scada.jpg"}
            SubContent={[
              "Tổng quan về phần mềm ICONICS",
              "",
              "",
              "Cách chọn phần mềm & triển khai dự án bằng Iconics",
              "",
              "",
              "Lập trình nâng cao phần mềm ICONICS",
              "",
              "",
            ]}
          />
          <Card
            Title={"Mạng truyền thông công nghiệp"}
            image={"/image/Trainning/cclink.jpg"}
            SubContent={[
              "Tổng quan các thiết bị trong mạng truyền thông công nghiệp Mitsubishi Electric",
              "Lập trình đầu nối các thiết bị trường: ELC, Servo, biến tần, HMI",
              "Cài đặt cấu hình mạng truyền thông công nghiệp Mitsubishi Electric",
            ]}
          />
          <Card
            Title={"Robot Mitsubishi Electric"}
            image={"/image/Trainning/Robot Mitsubishi Electric.jpg"}
            SubContent={[
              "Robot và ứng dụng của các loại robot trong công nghiệp",
              "",
              "",
              "Cách chọn robot cho một ứng dụng",
              "",
              "",
              "Lập trình cơ bản & nâng cao robot công nghiệp",
              "",
              "",
            ]}
          />
        </VStack>
      ) : (
        <VStack paddingTop={"64px"}>
          <Text
            fontSize={"24px"}
            fontWeight={600}
            // fontFamily={"'Oswald', sans-serif;"}
            textColor={"#0099cc"}
          >
            CHƯƠNG TRÌNH ĐÀO TẠO
          </Text>
          <Text>
            Chúng tôi nhận bồi dưỡng và đào tạo miễn phí cho các đối tác, khách
            hàng với các các chương trình sau
          </Text>
          <VStack
            display={"flex"}
            justifyContent="space-between"
            width={"1280px"}
            padding="64px 32px 0px 32px"
            data-aos="fade-up"
          >
            <HStack
              width={"100%"}
              spacing={"16px"}
              padding="16px"
              justifyContent="space-between"
            >
              <Card
                Title={"Thiết bị đóng cắt LVS"}
                image={"/image/Trainning/acb.jpg"}
                SubContent={[
                  "Tổng quan về thiết bị đóng cắt",
                  "",
                  "",
                  "Cách tính toán, lựa chọn thiết bị",
                  "",
                  "",
                  "Kiểm tra & lắp đặt máy cắt ACB",
                  "",
                  "",
                ]}
              />
              <Card
                Title={"Phần mềm ICONICS"}
                image={"/image/Trainning/scada.jpg"}
                SubContent={[
                  "Tổng quan về phần mềm ICONICS",
                  "",
                  "",
                  "Cách chọn phần mềm & triển khai dự án bằng Iconics",
                  "",
                  "",
                  "Lập trình nâng cao phần mềm ICONICS",
                  "",
                  "",
                ]}
              />
            </HStack>
            <HStack
              width={"100%"}
              spacing={"16px"}
              padding="16px"
              justifyContent="space-between"
            >
              <Card
                Title={"Mạng truyền thông công nghiệp"}
                image={"/image/Trainning/cclink.jpg"}
                SubContent={[
                  "Tổng quan các thiết bị trong mạng truyền thông công nghiệp Mitsubishi Electric",
                  "Lập trình đầu nối các thiết bị trường: ELC, Servo, biến tần, HMI",
                  "Cài đặt cấu hình mạng truyền thông công nghiệp Mitsubishi Electric",
                ]}
              />
              <Card
                Title={"Robot Mitsubishi Electric"}
                image={"/image/Trainning/Robot Mitsubishi Electric.jpg"}
                SubContent={[
                  "Robot và ứng dụng của các loại robot trong công nghiệp",
                  "",
                  "",
                  "Cách chọn robot cho một ứng dụng",
                  "",
                  "",
                  "Lập trình cơ bản & nâng cao robot công nghiệp",
                  "",
                  "",
                ]}
              />
            </HStack>
          </VStack>
        </VStack>
      )}
    </VStack>
  );
};

export default SolutionContainer;
