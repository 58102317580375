import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={25}
    fill="none"
    {...props}
  >
    <ellipse cx={12.948} cy={12.798} fill="#fff" rx={11.091} ry={10.417} />
    <path
      fill="#000"
      d="M18.943 7.738H7.057c-.817 0-1.478.603-1.478 1.34l-.008 8.035c0 .737.669 1.34 1.486 1.34h11.886c.817 0 1.486-.603 1.486-1.34V9.077c0-.736-.669-1.339-1.486-1.339Zm-.297 2.846-5.252 2.96a.822.822 0 0 1-.788 0l-5.252-2.96a.617.617 0 0 1-.191-.15.548.548 0 0 1-.111-.205.514.514 0 0 1 .076-.443.6.6 0 0 1 .175-.166.7.7 0 0 1 .72 0L13 12.426l4.977-2.806a.67.67 0 0 1 .487-.09.67.67 0 0 1 .233.09.6.6 0 0 1 .175.166.515.515 0 0 1 .076.443.549.549 0 0 1-.11.205.618.618 0 0 1-.192.15Z"
    />
  </svg>
);
export default SvgComponent;
