import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    fill="none"
    {...props}
  >
    <ellipse cx={12.948} cy={13.309} fill="#fff" rx={11.091} ry={10.833} />
    <path
      fill="#000"
      d="M14.647 6.19c-.898 0-1.759.364-2.394 1.011a3.486 3.486 0 0 0-.991 2.44v1.922H9.45a.166.166 0 0 0-.164.168v2.538c0 .092.073.168.164.168h1.812v5.205c0 .092.073.168.165.168h2.488c.09 0 .165-.075.165-.168v-5.205h1.827a.165.165 0 0 0 .16-.127l.622-2.538a.17.17 0 0 0-.088-.191.16.16 0 0 0-.072-.018h-2.45V9.643a.588.588 0 0 1 .167-.41.565.565 0 0 1 .4-.168h1.904c.09 0 .164-.075.164-.168V6.358a.166.166 0 0 0-.164-.168h-1.903Z"
    />
  </svg>
);
export default SvgComponent;
