import { Box, HStack, Image } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import LogoAue from "../../image/LogoAue.png";
import Menu from "../../components/Header/Menu";
import MobileMenu from "./MobileMenu";
import { Link, useLocation } from "react-router-dom";

const Header = (props) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [scrollState, setScrollState] = useState(0);
  const location = useLocation();
  const url = location.pathname;
  const [isSPPage, setISSPage] = useState();
  useEffect(() => {
    if (url === "/sanpham" || url === "/blog") {
      setISSPage(1);
    } else setISSPage(0);
  }, [url]);
  useEffect(() => {
    const handleScroll = () => {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        setScrollState(1);
      } else {
        setScrollState(0);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {isMobile ? (
        <MobileMenu />
      ) : (
        <Box
          position={"fixed"}
          top="0"
          zIndex={1000}
          {...props}
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          backgroundColor={
            isSPPage === 1
              ? "white"
              : scrollState === 0
              ? "rgba(0, 0, 0, 0.5)"
              : "white"
          }
          margin={"0 !important"}
          boxShadow={scrollState === 0 ? null : "0 0 10px #cccccc"}
          transition={"0.5s"}
        >
          <HStack
            width={"1280px"}
            height="74px"
            padding={"8px 32px"}
            display="flex"
            justifyContent={"space-between"}
          >
            <a href="./">
              <Image
                transition={"0.5s"}
                height={"42px"}
                src={LogoAue}
                alt="Aue Viet Nam"
              />
            </a>
            <Menu
              color={
                isSPPage === 1 ? "black" : scrollState === 0 ? "white" : "black"
              }
            />
          </HStack>
        </Box>
      )}
    </>
  );
};

export default Header;
