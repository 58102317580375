import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={42}
    height={42}
    fill="none"
    {...props}
  >
    <circle cx={21} cy={21} r={17.5} fill="#fff" />
    <path
      fill="#000"
      d="M18.6 24.429 24.828 21 18.6 17.571v6.858Zm13.872-8.949c.156.537.264 1.257.336 2.171a26.2 26.2 0 0 1 .12 2.389L33 21c0 2.503-.192 4.343-.528 5.52-.3 1.029-.996 1.691-2.076 1.977-.564.149-1.596.252-3.18.32a83.97 83.97 0 0 1-4.308.114L21 29c-5.028 0-8.16-.183-9.396-.503-1.08-.286-1.776-.948-2.076-1.977-.156-.537-.264-1.257-.336-2.171a26.2 26.2 0 0 1-.12-2.389L9 21c0-2.503.192-4.343.528-5.52.3-1.029.996-1.691 2.076-1.977.564-.149 1.596-.252 3.18-.32a83.97 83.97 0 0 1 4.308-.114L21 13c5.028 0 8.16.183 9.396.503 1.08.286 1.776.948 2.076 1.977Z"
    />
  </svg>
);
export default SvgComponent;
