import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={26}
    fill="none"
    {...props}
  >
    <ellipse cx={12.5} cy={13} fill="#000" rx={10.417} ry={10.833} />
    <path
      fill="#fff"
      d="M12.5 2.167C6.747 2.167 2.083 7.017 2.083 13c0 5.983 4.664 10.833 10.417 10.833S22.917 18.983 22.917 13c0-5.983-4.664-10.833-10.417-10.833ZM9.95 17.49H7.84v-7.06h2.11v7.06ZM8.882 9.564c-.666 0-1.097-.491-1.097-1.098 0-.62.444-1.096 1.125-1.096.68 0 1.097.476 1.11 1.096 0 .607-.43 1.098-1.138 1.098Zm8.772 7.926h-2.11v-3.912c0-.91-.305-1.53-1.068-1.53-.583 0-.929.42-1.082.822-.056.144-.07.347-.07.549v4.07h-2.11v-4.807c0-.882-.028-1.619-.056-2.253h1.833l.096.981h.042c.278-.46.959-1.14 2.097-1.14 1.387 0 2.428.967 2.428 3.046v4.174Z"
    />
  </svg>
);
export default SvgComponent;
