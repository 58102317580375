import { Box, VStack, Text } from "@chakra-ui/react";
import React from "react";
import InfoContainer from "../Homepage/InfoContainer";
import PartnerContainer from "../Homepage/PartnerContainer";
import SolutionContainer from "../Homepage/SolutionContainer";
import "./HomePage.css";

const Homepage = () => {
  return (
    <VStack
      width={"full"}
      display="flex"
      justifyContent={"center"}
      margin={"0 !important"}
      spacing={"64px"}
    >
      {/* <Image
        width={"full"}
        src="/image/solutionHomepage1.jpg"
        alt="industrial"
        objectFit="cover"
        height="500px"
      /> */}
      <Box
        className="ProjectCoverContainer"
        width={"full"}
        height="100vh"
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        position={"relative"}
      >
        <Box
          position={"absolute"}
          zIndex={999}
          width={"100%"}
          height={"100%"}
        ></Box>
        <Box id="video" width={"100%"} height={"100%"} top={0}>
          <iframe
            src="https://www.youtube.com/embed/1s-uUvvMaDo?playlist=1s-uUvvMaDo&loop=1&rel=0&&autoplay=1&controls=0&mute=1&disablekb=1&fs=0"
            title="YouTube video player"
            frameborder="0"
            allowfullscreen
            objectFit="cover"
          ></iframe>
        </Box>
        <VStack
          position={"absolute"}
          width={"1280px"}
          justifyContent={"flex-start"}
          alignContent={"flex-start"}
          alignItems={"flex-start"}
          textAlign={"left"}
          paddingLeft={"24px"}
        >
          <Text
            style={{
              textDecoration: "underline",
              textUnderlineOffset: "15px",
              textDecorationThickness: "3px",
            }}
            fontWeight={600}
            textColor={"#fff"}
            fontSize={"32px"}
          >
            CÔNG TY TNHH AUE VIỆT NAM
          </Text>
          <section class="animation">
            <div>
              <div>TRUNG THỰC</div>
            </div>
            <div>
              <div>BIẾT ƠN & CHIA SẺ</div>
            </div>
            <div>
              <div>TRÁCH NHIỆM</div>
            </div>
            <div>
              <div>PHÁT TRIỂN BỀN VỮNG</div>
            </div>
            <div>
              <div>TRUNG THỰC</div>
            </div>
          </section>
        </VStack>
      </Box>

      <InfoContainer />
      <SolutionContainer />
      {/* <EquipmentContainer /> */}
      <PartnerContainer />
    </VStack>
  );
};

export default Homepage;
