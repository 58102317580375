import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={35}
    height={35}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <circle cx={17.5} cy={17.5} r={17.5} fill="#fff" />
      <path
        d="M18.072 15.484v-.525h1.571v7.376h-.898a.672.672 0 0 1-.673-.669 3.832 3.832 0 0 1-6.092-3.09 3.832 3.832 0 0 1 6.092-3.092Zm-6.5-2.896v.24c0 .445-.06.81-.35 1.236l-.035.04c-.097.108-.19.22-.282.332l-5.044 6.33h5.711v.897a.673.673 0 0 1-.673.672H3.5v-.423c0-.517.128-.747.292-.988l5.374-6.656H3.724v-1.68h7.848Zm9.976 9.747a.56.56 0 0 1-.56-.56v-9.187h1.681v9.747h-1.12Zm6.094-7.635a3.858 3.858 0 1 1 .002 7.716 3.858 3.858 0 0 1-.002-7.716Zm-11.83 6.128a2.254 2.254 0 1 0 .102-4.506 2.254 2.254 0 0 0-.102 4.506Zm11.83-.003a2.27 2.27 0 1 0 0-4.539 2.27 2.27 0 0 0 0 4.539Z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h35v35H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
