import React, { useState, useEffect } from "react";
import { Box, Image, VStack, Text, HStack } from "@chakra-ui/react";
import SolutionCardContainer from "../Solution/SolutionCardContainer";
import { Helmet } from "react-helmet";

const Solution = () => {
  const [isAnimationActive, setIsAnimationActive] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnimationActive(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);
  const styles = {
    text: {
      paddingRight: "68px",
      fontFamily: "poppins",
      fontWeight: "bold",
      textColor: "white",
      fontSize: "120px",
      letterSpacing: "5px",
      animation: isAnimationActive ? "shrinkAnimation 5s linear" : "none",
    },
  };
  const textStyle = {
    color: "white",
    WebkitTextFillColor: "transparent",
    WebkitTextStrokeWidth: "1px",
    WebkitTextStrokeColor: "white",
    letterSpacing: "5px",
    animation: isAnimationActive ? "shrinkAnimation 5s linear" : "none",
  };
  return (
    <VStack
      width={"100%"}
      display="flex"
      justifyContent={"center"}
      spacing={"32px"}
      margin={"0 !important"}
      paddingBottom={"100px"}
    >
      <Helmet>
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
        </style>
      </Helmet>
      <VStack
        className="ProjectCoverContainer"
        width={"full"}
        height="100vh"
        display={"flex"}
        justifyContent={"center"}
        alignItems={"flex-end"}
        position={"relative"}
        backgroundImage={"url(/image/coverSolution.jpg)"}
        backgroundRepeat={"no-repeat"}
        backgroundSize={"cover"}
        padding={"32px"}
      >
        <style>
          {`
          @keyframes shrinkAnimation {
            from {
              letter-spacing: 10px;
            }
            to {
              letter-spacing: 5px;
            }
          }
        `}
        </style>
        <Text textColor={"white"} style={styles.text}>
          SOLUTION
        </Text>
        <Text
          bottom={"25%"}
          right={"15%"}
          position={"absolute"}
          fontFamily={"poppins"}
          style={textStyle}
          fontSize={"120px"}
        >
          SOLUTION
        </Text>
      </VStack>
      <Text
        textAlign={"start"}
        textColor={"#0099cc"}
        fontWeight={600}
        fontSize={"24px"}
      >
        CUNG CẤP NHIỀU GIẢI PHÁP
      </Text>
      <SolutionCardContainer />
      <HStack backgroundColor={"#f0f0f0"} width={"1250px"}>
        <VStack
          width={"50%"}
          paddingLeft={"68px"}
          alignItems={"flex-start"}
          textAlign={"left"}
        >
          <Text fontWeight={700}>CHỨNG NHẬN ICONICS</Text>
          <Text textAlign={"justify"}>
            AUE Việt Nam vinh hạnh khi được đồng hành với Mitsubishi Electric
            Việt Nam như một đối tác tích hợp hệ thống độc quyền Về
            <a
              href="http://www.iconics.com"
              rel="noreferrer"
              target="_blank"
              style={{
                color: "#0099cc",
              }}
            >
              {" "}
              SCADA software-ICONICS Suite™
            </a>
            . Là một trong những công ty hàng đầu thế giới trong việc cung cấp
            giải pháp phần mềm tự động hóa tiên tiến nhất cho nhiều ngành công
            nghiệp trên toàn cầu. <br />
            <br />
            Giải pháp phần mềm của{" "}
            <a
              href="http://www.iconics.com"
              rel="noreferrer"
              target="_blank"
              style={{
                color: "#0099cc",
              }}
            >
              ICONICS
            </a>{" "}
            bao gồm quản lý trực quan nâng cao, lưu trữ và truy xuất nhanh
            chóng, phân tích dữ liệu chuyên sâu, khả năng tương thích trên nhiều
            thiết và tối ưu hóa IIoT/đám mây.
            <br />
            <br /> Đặc biệt, những công cụ sáng tạo tích hợp như trực quan hóa,
            lập lịch sử, phân tích, triển khai và quản lý dữ liệu đám mây theo
            quy trình và yêu cầu của khách hàng, giúp{" "}
            <a
              href="http://www.iconics.com"
              rel="noreferrer"
              target="_blank"
              style={{
                color: "#0099cc",
              }}
            >
              ICONICS
            </a>{" "}
            vượt trội hơn so với các đối thủ.
          </Text>
        </VStack>
        <Image width={"50%"} src="./image/v01.jpg" />
      </HStack>
    </VStack>
  );
};

export default Solution;
