import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={42}
    height={42}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse cx={20.917} cy={21.5} rx={17.917} ry={17.5} fill="#fff" />
    <path
      d="M23.66 10c-1.45 0-2.84.587-3.866 1.633a5.631 5.631 0 0 0-1.602 3.942v3.105h-2.926a.268.268 0 0 0-.266.27v4.1c0 .149.118.27.266.27h2.926v8.409c0 .15.118.271.266.271h4.02c.146 0 .266-.12.266-.271V23.32h2.952a.267.267 0 0 0 .258-.204l1.005-4.099a.277.277 0 0 0-.048-.233.268.268 0 0 0-.21-.104h-3.957v-3.105a.95.95 0 0 1 .268-.66.917.917 0 0 1 .648-.274h3.074c.147 0 .266-.12.266-.271V10.27a.268.268 0 0 0-.266-.271H23.66Z"
      fill="#000"
    />
  </svg>
);

export default SvgComponent;
