import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={48}
    height={48}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.26 44.38 23 36.72c3.14-1.16 6.08-2.72 8.8-4.54l-5.54 12.2ZM11.28 25l-7.66-3.26 12.2-5.54c-1.82 2.72-3.38 5.66-4.54 8.8ZM43.22 4.78S33.32.538 22 11.86c-4.38 4.38-7 9.2-8.7 13.42-.56 1.5-.18 3.14.92 4.26l4.26 4.24c1.1 1.12 2.74 1.48 4.24.92A38.2 38.2 0 0 0 36.14 26c11.32-11.32 7.08-21.22 7.08-21.22ZM29.08 18.92a4.008 4.008 0 0 1 0-5.66 4.008 4.008 0 0 1 5.66 0c1.54 1.56 1.56 4.1 0 5.66a4.008 4.008 0 0 1-5.66 0ZM12.48 44l7.28-7.28c-.68-.18-1.34-.48-1.94-.9L9.66 44h2.82ZM4 44h2.82l9.54-9.52-2.84-2.82L4 41.18V44Zm0-5.66 8.18-8.16c-.42-.6-.72-1.24-.9-1.94L4 35.52v2.82Z"
      fill="#fff"
    />
  </svg>
);

export default SvgComponent;
