import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#09C"
      d="m7.067 11.067 4.7-4.7-.934-.934L7.067 9.2l-1.9-1.9-.934.933 2.834 2.834Zm.933 3.6a6.487 6.487 0 0 1-2.6-.526 6.743 6.743 0 0 1-2.117-1.424A6.728 6.728 0 0 1 1.86 10.6 6.503 6.503 0 0 1 1.333 8c0-.922.175-1.789.526-2.6a6.743 6.743 0 0 1 1.424-2.117c.6-.6 1.306-1.075 2.117-1.424A6.503 6.503 0 0 1 8 1.333c.922 0 1.789.175 2.6.526.811.35 1.517.825 2.117 1.424.6.6 1.075 1.306 1.425 2.117.35.811.525 1.678.525 2.6 0 .922-.175 1.789-.526 2.6a6.743 6.743 0 0 1-1.424 2.117c-.6.6-1.306 1.075-2.117 1.425a6.481 6.481 0 0 1-2.6.525Zm0-1.334c1.489 0 2.75-.516 3.783-1.55 1.034-1.033 1.55-2.294 1.55-3.783 0-1.489-.516-2.75-1.55-3.783C10.75 3.183 9.49 2.667 8 2.667c-1.489 0-2.75.516-3.783 1.55C3.183 5.25 2.667 6.51 2.667 8c0 1.489.516 2.75 1.55 3.783C5.25 12.817 6.51 13.333 8 13.333Z"
    />
  </svg>
);
export default SvgComponent;
