import { Box, VStack, Text, Container, Image } from "@chakra-ui/react";
import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const Blog = () => {
  const DataBlog = [
    {
      image: "https://picsum.photos/200/100",
      title: "servo",
    },
    {
      image: "https://picsum.photos/300/200",
      title: "bg",
    },
    {
      image: "https://picsum.photos/250/100",
      title: "biến tần",
    },
    {
      image: "https://picsum.photos/200/300",
      title: "servo",
    },
    {
      image: "https://picsum.photos/400/200",
      title: "bg",
    },
    {
      image: "https://picsum.photos/300",
      title: "biến tần",
    },
    {
      image: "https://picsum.photos/200/100",
      title: "servo",
    },
    {
      image: "https://picsum.photos/300/200",
      title: "bg",
    },
    {
      image: "https://picsum.photos/250/100",
      title: "biến tần",
    },
    {
      image: "https://picsum.photos/200/300",
      title: "servo",
    },
    {
      image: "https://picsum.photos/400/200",
      title: "bg",
    },
    {
      image: "https://picsum.photos/300",
      title: "biến tần",
    },
  ];
  const CardBlog = ({ img, title }) => {
    return (
      <VStack alignItems={"flex-start"}>
        <Image width={"100%"} src={img} />
        <Text paddingLeft={"16px"}>{title}</Text>
      </VStack>
    );
  };
  return (
    <VStack width={"100%"} spacing={"68px"}>
      <VStack
        width={"full"}
        height={"128px"}
        marginTop={"72px"}
        backgroundColor={"#f1f1f1"}
        justifyContent={"center"}
      >
        <Text fontSize={"18px"} fontWeight={"600"}>
          Blog
        </Text>
      </VStack>
      <Box width={"1280px"}>
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
          <Masonry gutter="10px">
            {DataBlog.map((item, index) => (
              <CardBlog key={index} img={item.image} title={item.title} />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </Box>
    </VStack>
  );
};

export default Blog;
