import * as React from "react";

const SvgComponent = ({ props, color }) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 8c.367 0 .68-.13.942-.392.26-.26.391-.575.391-.941 0-.367-.13-.681-.391-.942A1.286 1.286 0 0 0 8 5.333c-.367 0-.68.13-.941.392a1.284 1.284 0 0 0-.392.942c0 .366.13.68.392.941.26.261.574.392.941.392Zm0 6.667c-1.789-1.523-3.125-2.937-4.008-4.242C3.108 9.119 2.667 7.91 2.667 6.8c0-1.667.536-2.994 1.608-3.983C5.347 1.827 6.59 1.333 8 1.333s2.653.495 3.725 1.484 1.608 2.316 1.608 3.983c0 1.111-.441 2.32-1.324 3.625-.884 1.305-2.22 2.72-4.009 4.242Z"
      fill={color}
    />
  </svg>
);

export default SvgComponent;
